import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import SampleForm from '../components/Form'
import moneydialogue from '../img/money-dialogue.svg'
import swish from '../img/swish.png'
// import swishw from '../img/swish-white.png'
import empben from '../img/employer-benefits-icon.png'
import whitebranch from '../img/large-branch-white.png'
// import leaf from '../img/03-leaf.svg'
import plant from '../img/001-plant.svg'
// import plants from '../img/02-plant-1.svg'
import leafs from '../img/003-leaf.svg'

class OurServicesPage extends React.Component {
  render() {
    const siteTitle = 'Our Services'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="atf-service">
          <div className="container">
            <div className="col-md-12">
              <h1 className="atf-h text-center text-white">
                <strong>Our Services</strong>
              </h1>
            </div>
          </div>
        </div>

        <div className="our-service">
          <div className="container">
            <div className="col-md-12">
              <div className="bg text-center">
                <img alt="empben" src={empben} />
              </div>
              <h1 className="section-h text-center">
                <strong>What We Do</strong>
              </h1>
              <p className="about-p">
                Communication and trust is at the heart of everything we do. We
                are passionate about developing long-term relationships,
                standing beside you through the many changes that life
                inevitably holds for us all. We understand you and your needs
                because we share many of them, too. From financial priorities
                like retirement, or educating our kids, to other goals like
                career aspirations and creating a sense of community at home and
                at work, we view everything from a place of unity and
                understanding.
                <br />
                <br />
                We make working with us as accessible as possible through our
                transparent fee-based services. We also hold{' '}
                <strong>
                  <em>fiduciary status</em>
                </strong>
                , meaning we’re legally bound to put your best interests first,
                always. We hold ourselves to the highest ethical standards, so
                you can feel confident that your money is being used to serve
                YOU, not us.
              </p>
              <div className="swish text-center">
                <img alt="swish" src={swish} />
              </div>
            </div>
          </div>
        </div>
        <div className="service-table2">
          <div className="container-fluid">
            <div className="bg text-center">
              <img alt="plant" src={plant} />
            </div>
            <h1 className="section-h text-center text-white">
              <strong>Planning</strong>
            </h1>
            <div className="row">
              <div className="panel pricing-table">
                <div className="pricing-plan">
                  <h2 className="pricing-header">Goals</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Clients are ready to clarify, simplify, and organize their
                      financial life. They see the value in partnering with
                      experienced Advisors to take a holistic approach to
                      defining goals, analyzing options, making informed
                      decisions, evaluating risks, and implementing strategies
                      to pursue their financial goals and minimize risks.
                      Clients want to focus on what matters most in life and
                      partner with us on an ongoing basis to stay informed,
                      access objective advice, and make adjustments as changes
                      in investments, finances, laws, and life occur.
                    </li>
                  </ul>

                  {/* <span className="pricing-price">Free</span>
        <a href="#/" className="pricing-button">Sign up</a>*/}
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header">Services</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Together we design and implement an integrated financial
                      plan covering selected aspects of client’s financial life,
                      which can include:
                    </li>
                    <li>
                      <div className="row">
                        <ul className="col-md-12">
                          <li className="pricing-features-item">
                             ✓ Building, preserving and distributing wealth
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Portfolio construction
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Comprehensive asset allocation
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Tax planning • Estate planning
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Insurance
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Budget and cash flow analysis
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Retirement (and other goal) planning
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Executive compensation
                          </li>
                        </ul>
                      </div>{' '}
                    </li>
                    <li className="pricing-features-item">
                      Clients partner with us, initially and over time, to
                      manage and maintain their financial plan through ongoing
                      communications and collaboration that reflects changes in
                      their life, planning strategies, the laws, and the
                      investment markets. Asset allocation does not ensure a
                      profit or protect against a loss.
                    </li>
                  </ul>
                  {/* <span className="pricing-price">$150</span>
        <a href="#/" className="pricing-button is-featured">Free trial</a>*/}
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header">Fees</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Minimum investable assets: $500,000. The Financial Review
                      is a one-time fee that covers a customized review of your
                      financial situation, goals, and needs, and provides
                      specific recommendations. Fees range from $2,500-$7,500
                      depending on situation and complexity.
                    </li>
                    <li className="pricing-features-item">
                      Annual planner engagements provide ongoing review, advice
                      and management of financial strategies. Minimum annual
                      fees: $1,250. Investment Management fees apply and are in
                      addition to the financial planning fee
                    </li>
                  </ul>
                  {/* <span className="pricing-price">$400</span>
        <a href="#/" className="pricing-button">Free trial</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-table">
          <div className="container-fluid">
            <div className="bg text-center">
              <img alt="leafs" src={leafs} />
            </div>
            <h1 className="section-h text-center text-justify text-white">
              <strong>Investment Management</strong>
            </h1>
            <div className="row">
              <div className="panel pricing-table">
                <div className="pricing-plan">
                  <h2 className="pricing-header">Goals</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Clients seek professional knowledge, expertise and support
                      to align their investment strategy with their financial
                      goals. Clients see the value in partnering with
                      experienced Advisors to design, implement and manage an
                      investment strategy, including investment research,
                      analysis and selection. Clients view professional
                      management as a way to accomplish investment objectives
                      while freeing up valued personal time.
                    </li>
                  </ul>

                  {/* <span className="pricing-price">Free</span>
        <a href="#/" className="pricing-button">Sign up</a>*/}
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header">Services</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Together we design and implement an investment strategy
                      appropriate to your situation, risk tolerance and goals.
                      Clients receive ongoing investment management guidance and
                      service, which can include:
                    </li>
                    <li>
                      <div className="row">
                        <ul className="col-md-12">
                          <li className="pricing-features-item">
                             ✓ Investment goals / Strategy
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Risk tolerance
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Investment Research
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Investment Analysis
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Investment Selection
                          </li>
                        </ul>
                      </div>{' '}
                    </li>
                    <li className="pricing-features-item">
                      Clients experience support and communication as it relates
                      to investment management. Important to this process is a
                      qualitative discussion regarding client risk parameters.
                      We follow a regular cycle to implement and monitor client
                      investments.
                    </li>
                  </ul>
                  {/* <span className="pricing-price">$150</span>
        <a href="#/" className="pricing-button is-featured">Free trial</a>*/}
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header">Fees</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Minimum investable assets: $500,000. Investment Management
                      fees (platform + advisor) range from .75% to 1.00%,
                      varying with platform and investment structure:
                    </li>

                    <li>
                      <div className="row">
                        <ul className="col-md-6">
                          <li className="pricing-features-item li-blue price-size">
                            $500,000—$749,000
                          </li>
                          <li className="pricing-features-item li-blue price-size">
                            $750,000—$1,249,999
                          </li>
                          <li className="pricing-features-item li-blue price-size">
                            $1,250,000—$4,999,999
                          </li>
                          <li className="pricing-features-item li-blue price-size">
                            $5,000,000 +
                          </li>
                        </ul>
                        <ul className="col-md-6">
                          <li className="pricing-features-item li-blue price-size">
                            0.23% + 0.77%
                          </li>
                          <li className="pricing-features-item li-blue price-size">
                            0.18% + 0.72%
                          </li>
                          <li className="pricing-features-item li-blue price-size">
                            0.16% + 0.64%
                          </li>
                          <li className="pricing-features-item li-blue price-size">
                            0.13% + 0.62%
                          </li>
                        </ul>
                      </div>{' '}
                    </li>
                  </ul>
                  {/* <span className="pricing-price">$400</span>
        <a href="#/" className="pricing-button">Free trial</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service-table3">
          <div className="container-fluid">
            <div className="bg text-center">
              <img alt="whitebranch" src={whitebranch} />
            </div>
            <h1 className="section-h text-center text-white">
              <strong>Risk Management</strong>
            </h1>
            <div className="row">
              <div className="panel pricing-table">
                <div className="pricing-plan">
                  <h2 className="pricing-header">Goals</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Clients seek targeted strategies to identify, quantify and
                      transfer financial risks that are appropriate for their
                      circumstances and goals, which can include:
                    </li>
                    <li>
                      <div className="row">
                        <ul className="col-md-12">
                          <li className="pricing-features-item li-blue">
                            ✓ Life Insurance
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Long Term Care
                          </li>
                          <li className="pricing-features-item li-blue">
                            ✓ Disability protection
                          </li>
                        </ul>
                      </div>{' '}
                    </li>
                  </ul>

                  {/* <span className="pricing-price">Free</span>
        <a href="#/" className="pricing-button">Sign up</a>*/}
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header">Services</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Clients receive guidance, advice and service related to
                      their specifically defined needs and concerns. Additional
                      services, outside of scope of work defined, will be
                      determined separately as requested or recommended.
                      Investment Management and Financial Planning services are
                      available separately.
                    </li>
                  </ul>
                  {/* <span className="pricing-price">$150</span>
        <a href="#/" className="pricing-button is-featured">Free trial</a>*/}
                </div>

                <div className="pricing-plan">
                  <h2 className="pricing-header">Fees</h2>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">
                      Fees vary and are associated with the products selected
                      and will be reviewed at the time of discussion.
                    </li>
                  </ul>
                  {/* <span className="pricing-price">$400</span>
        <a href="#/" className="pricing-button">Free trial</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="newsletter" className="cont">
          <div className="container">
            <div className="col-md-12">
              <div className="bg text-center">
                <img alt="moneydialogue" src={moneydialogue} />
              </div>
              <h1 className="section-h text-center text-white">
                <strong>
                  Get advice from Journey Financial Services&nbsp;
                </strong>
              </h1>
              <p className="service-p text-white">
                <em>
                  You will automatically be subscribed to our quarterly
                  newsletter&nbsp;to receive e-mails&nbsp;filled with articles,
                  advice and financial guidance
                </em>
              </p>
            </div>
          </div>
          <div className="container">
            <SampleForm
              form_name="Newsletter Form"
              form_id="5df865b7676f4567eacd2532"
              form_classname="form-newsletter"
              className="row justify-content-center"
              method="post"
            >
              <div className="form-row">
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Name"
                      id="full_name"
                    />
                    <label htmlFor="full_name">Enter Your Name</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="email"
                      name="Email Address"
                      id="email_address"
                    />
                    <label htmlFor="email_address">Email</label>
                  </div>
                  <small className="form-text text-danger">
                    Please enter a correct email address.
                  </small>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="MailingAddress"
                      id="mailing_address"
                    />
                    <label htmlFor="mailing_address">Mailing Address</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Phone"
                      id="phone"
                    />
                    <label htmlFor="phone">Phone</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <button
                      className="btn btn-primary bttn float-left c-button"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                </div>
              </div>
            </SampleForm>
          </div>
        </div>
        {/* <SampleForm
            form_name="Newsletter Form"
            form_id="5bcd42f86b63453b251972bc"
            form_classname="form-newsletter"
          >
          <div className="form-row">
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="Email Address"
                id="email_address"
              />
              <label htmlFor="email_address">
                Enter Your Email Address
              </label>
            </div>
            <small className="text-dark form-text mt-2">
                We will never share your email with anyone else.
              </small>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="Name"
                id="full_name"
              />
              <label htmlFor="full_name">
                Enter Your Name
              </label>
            </div>
          </div>
          <div className="col-12">
            
              <div className="webriq-recaptcha" />
            
          </div>

          <div className="col-12">
            <div className="form-group">
              <button
                className="btn btn-primary hvr-shadow"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
          </SampleForm> */}
      </Layout>
    )
  }
}
export default OurServicesPage

export const OurServicesPageQuery = graphql`
  query OurServicesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
